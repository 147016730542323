import './index.html';
import './style.scss';
import {imageLazyLoading} from "../../scripts/functions/imageLazyLoading";
import {animations} from "../../scripts/general/animations";
import {gsap} from "gsap";
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import 'magnific-popup';
import 'magnific-popup/dist/magnific-popup.css';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";

gsap.registerPlugin(SplitText, ScrollTrigger);

/**
 *
 * @param block {HTMLElement}
 * @returns {Promise<void>}
 */
const heroBlock = async (block) => {

    const heroCard = block.querySelector(".hero-card")
    const growCard = block.querySelector(".grow-up-card")
    const sectionBg = block.querySelector(".section-background")
    const heroImage = block.querySelector(".hero-image")
    const nextSibling = block.nextElementSibling;

    let tl = gsap.timeline(),
        mySplitText = new SplitText(".split-text", {type: "lines"}),
        chars = mySplitText.lines;
    if (block.classList.contains("version-2") || block.classList.contains("version-3")) {
        tl.from(chars, {
            duration: 0.6,
            opacity: 0,
            y: 50,
            stagger: .1,
            ease: 'power1.inOut',
            transformStyle: "preserve-3d",
            transformOrigin: "center center"
        })
            .from(heroImage, {
                yPercent: 100
            }, "<50%")
    } else {
        tl.from(chars, {
            duration: 0.6,
            opacity: 0,
            y: 50,
            stagger: .1,
            ease: 'power1.inOut',
            transformStyle: "preserve-3d",
            transformOrigin: "center center"
        })

            .from(heroCard, {
                opacity: 0,
                yPercent: 50,
                duration: 1,
                ease: "power3"
            }, "<50%")
        if (window.innerWidth >= 600) {
            gsap.from(growCard, {
                scrollTrigger: {
                    trigger: block,
                    scrub: .2,
                    start: "top top",
                    end: "+=400",
                    pin: true,
                },
                scale: .9,
                yPercent: 100,
                duration: 1,
            })
        } else {
            gsap.timeline({
                scrollTrigger: {
                    trigger: block,
                    scrub: .2,
                    start: "top top",
                    end: "+=800",
                    pin: true,
                }
            })
                .from(growCard, {
                    scale: .9,
                    yPercent: 100,
                    duration: 1,
                })

                .fromTo(block, {
                    height: "85rem"
                }, {
                    duration: 1,
                    height: "auto"
                }, "<")
                .to(sectionBg, {
                    height: "100%"
                }, "<")
        }
    }

    $(() => {
        $('.select2-enable').select2();
    });

    const play = block.querySelector('.hero-video-poster');
    const source = block.querySelector('.hero-video-source');
    let player;

    if (!!source && source.tagName === 'IFRAME') {
        source.setAttribute('src', source.getAttribute('src').replace('watch?v=', 'embed/') + '?enablejsapi=1&autoplay=1&mute=1&controls=0&loop=1');
        
        if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            window.onYouTubePlayerAPIReady = function () {
                onYouTubeIframeAPIReady();
            };
        }

        function onYouTubeIframeAPIReady() {
            player = new YT.Player('youtube-video', {
              events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
              }
            });
        }

        function onPlayerReady() {
            console.log("hey Im ready");
        //do whatever you want here. Like, player.playVideo();
        }
        
        function onPlayerStateChange() {
            console.log("my state changed");
        }
    }

    if (!!play) {
        play.addEventListener('click', (e) => {
            const heroVideo = block.querySelector('.hero-video');
            const source = block.querySelector('.hero-video-source');

            if (!!source) {
                if (source.tagName === 'VIDEO') {
                    heroVideo.classList.toggle('hero-video_is-playing');
    
                    if (heroVideo.classList.contains('hero-video_is-playing')) {
                        source.muted = false;
                    } else {
                        source.muted = true;
                    }
                } else if (source.tagName === 'IFRAME') {
                    // setTimeout(() => {
                    //     heroVideo.classList.add('hero-video_is-playing');
                    // }, 150)
    
                    heroVideo.classList.toggle('hero-video_is-playing');
    
                    if (heroVideo.classList.contains('hero-video_is-playing')) {
                        player.unMute();
                    } else {
                        player.mute();
                    }
                }
            }

        });
    }

    watchBandImageWithTextBlock();
    version3MobileBgHeightHandler();

    window.addEventListener('resize', () => {
        watchBandImageWithTextBlock();
        version3MobileBgHeightHandler();
    });

    function watchBandImageWithTextBlock() {
        if (nextSibling.classList.contains('band_image_with_text')) {
            if (window.innerWidth > 992) {
                block.style.paddingBottom = '13.6rem';
            } else {
                block.setAttribute('style', '');
            }
        }
    }

    function version3MobileBgHeightHandler() {
        const bg = block.querySelector('.section-background');

        if (block.classList.contains('version-3--with-description') && window.innerWidth < 600) {
            const heroText = block.querySelector('.hero-text');

            if (!!heroText) {
                const heroTextBCR = heroText.getBoundingClientRect();

                bg.style.height = `${window.scrollY + heroTextBCR.top + heroTextBCR.height + 100}px`;
            }
        } else {
            bg.style.height = '';
        }
    }


    animations(block);
    imageLazyLoading(block);
};

export default heroBlock;

