import './index.html';
import './style.scss';
import {imageLazyLoading} from '../../scripts/functions/imageLazyLoading';
import {animations} from '../../scripts/general/animations';
import {gsap} from "gsap";

/**
 *
 * @param block {HTMLElement}
 * @returns {Promise<void>}
 */
export default async (header) => {

  // region save headerSticky height
  // header.classList.add('header-sticky');
  // window.headerSticky = header?.getBoundingClientRect()?.height - 2 ?? 0;
  // header.classList.remove('header-sticky');
  // endregion save headerSticky height

  // save headerSticky height
  // header.classList.add('sticky');
  // document.documentElement.style.setProperty('--header-sticky', header?.getBoundingClientRect()?.height - 2 ?? 0);
  // window.headerSticky = 78
  // header.dataset.headerSticky = 78;
  // header.classList.remove('sticky');
//
  const menuItems = header.querySelectorAll('.menu-item-has-children');
  const navMenu = header.querySelector('.btn-navmenu');
  const menuLinks = header.querySelector('.navbar');
  const overLay = header.querySelector('.overlay');
  const menuLink = header.querySelector('.menu-item')
  const openMegaMenuICon = header.querySelectorAll('.open-mega-menu');
  const btnBack = header?.querySelectorAll('.back-link');
  const mobileMedia = window.matchMedia('(max-width: 992px)');

  overLay?.addEventListener('click', () => {
    document.documentElement.classList.remove('modal-opened');
    navMenu.classList.remove('nav-menu-active');
    menuLinks.classList.remove('header-links-active');
    menuLink.classList.remove('menu-item-active');
    header.classList.remove('header-active');
  })

  navMenu?.addEventListener('click', function () {
    if (navMenu.classList.contains('nav-menu-active')) {
      // region allow page scroll
      document.documentElement.classList.remove('modal-opened');
      // endregion allow page scroll
      navMenu.classList.remove('nav-menu-active');
      menuLinks.classList.remove('header-links-active');
      menuLink.classList.remove('menu-item-active');
      header.classList.remove('header-active');
      // burgerTl.timeScale(2).reverse();
    } else {
      navMenu.classList.add('nav-menu-active');
      menuLinks.classList.add('header-links-active');
      header.classList.add('header-active');

      document.documentElement.classList.add('modal-opened');
      // endregion prevent page scroll
      gsap.fromTo(menuLinks.querySelectorAll('.menu-item'), {
        y: 30,
        autoAlpha: 0,
      }, {
        y: 0,
        autoAlpha: 1,
        stagger: .05,
        duration: .2,
        delay: .2,
        clearProps: true
      });
    }
  });
  menuItems.forEach((menuItem) => {
    const menuItemBody = menuItem.querySelector('.sub-menu');
    menuItem?.addEventListener('click', (e) => {

      if (!mobileMedia.matches || !menuItemBody || e.target.classList.contains('header-link') || e.target.closest('.sub-menu,.menu-item-in-sub-menu a')) return;
      const isOpened = menuItem?.classList.toggle('menu-item-active');
      if (!isOpened) {
        gsap.to(menuItemBody, {height: 0});
      } else {
        gsap.to(Array.from(menuItems).map(otherMenuItem => {
          const otherMenuItemBody = otherMenuItem.querySelector('.sub-menu');
          if (otherMenuItemBody && menuItem !== otherMenuItem) {
            otherMenuItem?.classList.remove('menu-item-active');
            gsap.set(otherMenuItem, {zIndex: 1});
          }
          return otherMenuItemBody;
        }), {height: 0});
        gsap.set(menuItem, {zIndex: 2});
        gsap.to(menuItemBody, {height: 'auto'});
      }
    });
  });

  // endregion open sub menu in responsive


  function OPenMenu() {
    menuItems?.forEach((menuItem) => {
      const subMenu = menuItem.querySelector('.sub-menu');
      menuItem?.addEventListener('click', (e) => {
        if (!mobileMedia.matches || !subMenu || e.target.classList.contains('header-link') || e.target.closest('.sub-menu,.menu-item-in-sub-menu a')) return;
        const isOpened = menuItem?.classList.toggle('menu-item-active');
        if (!isOpened) {
          gsap.to(subMenu, {height: 0});
        } else {
          gsap.to(Array.from(menuItems).map(otherMenuItem => {
            const otherMenuItemBody = otherMenuItem.querySelector('.sub-menu');
            if (otherMenuItemBody && menuItem !== otherMenuItem) {
              otherMenuItem?.classList.remove('menu-item-active');
              gsap.set(otherMenuItem, {zIndex: 1});
            }
            return otherMenuItemBody;
          }), {height: 0});
          gsap.set(menuItem, {zIndex: 2});
          gsap.to(subMenu, {height: 'auto'});
        }
      });
    });
    openMegaMenuICon?.forEach((icon) => {
      const ParentOfMegaMenu = icon.closest('.menu-item-has-children');
      icon?.addEventListener('click', (e) => {
        ParentOfMegaMenu.classList.add('menu-item-active');
      });
    });
    btnBack?.forEach((backButton) => {
      const ParentOfMegaMenu = backButton.closest('.menu-item-has-children');
      backButton?.addEventListener('click', function (e) {
        e.stopPropagation();
        ParentOfMegaMenu.classList.remove('menu-item-active');
      });
    });
  }

  // watch mobile bottom panel
  resizeHandler();
  window.visualViewport.addEventListener("resize", resizeHandler);

  function resizeHandler() {
    if (window.innerWidth < 992) {
      menuLinks.style.height = `${window.innerHeight - header.offsetHeight}px`;
    } else {
      menuLinks.style.height = 'auto';
    }
  }

  if (mobileMedia.matches) {
    OPenMenu();
  }

  const scrollDown = "scroll-down";
  const gradient = header.querySelector('.gradient');
  let lastScroll = 0;
  window.addEventListener("scroll", () => {
    const currentScroll = window.scrollY;
    if (currentScroll <= 50) {
      gradient.classList.add('hide-me')
      header.classList.remove(scrollDown);
    } else  {
      if (window.accordionIsOpen === false) {
        gradient.classList.remove('hide-me')
        header.classList.toggle(scrollDown, currentScroll > lastScroll);
      }
    }
    lastScroll = currentScroll;
  });
  // const enableTransition = debounce(()=>header.classList.remove('no-transition'),500);
  // window.addEventListener("resize", resizeHandler);
  // resizeHandler();
  // function resizeHandler() {
  //   if (window.innerWidth >= 992) {
  //     enableTransition();
  //   } else {
  //     header.classList.add('no-transition')
  //   }
  // }


  // header.classList.toggle('sticky', window.scrollY >= 20);
  // window.addEventListener('scroll', function () {
  //   header.classList.toggle('sticky', window.scrollY >= 20);
  // });


  animations(header);
  imageLazyLoading(header);
};

